// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Component, StrictMode } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import routes from 'routes';
import apolloClient from '../apolloClient';
import LocaleProvider from './LocaleProvider';

import { ModuleProvider, DynamicModule } from 'components/DynamicModule';

class App extends Component {
    state = {
        widgets: [],
    };

    router = createBrowserRouter(routes);

    addWidget(widget) {
        this.setState(({ widgets }) => ({
            widgets: [
                ...widgets,
                widget,
            ],
        }));
    }

    render() {
        return (
            <StrictMode>
                <ModuleProvider>
                    <ApolloProvider client={apolloClient}>
                        <LocaleProvider>
                            <RouterProvider router={this.router} />
                            {this.state.widgets}
                        </LocaleProvider>
                    </ApolloProvider>
                    <DynamicModule />
                </ModuleProvider>
            </StrictMode>
        );
    }
}

export default App;

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLocalStorage } from 'common/hooks';
import { getGraphQlListInputStorageArgs } from './helpers';

const ITEMS_PER_PAGE_OPTIONS = [10, 25, 100, 'all'];

const useGraphQlList = ({
    key,
    defaultInput,
    query,
    variables = {},
    withNetworkOnlyStrategy = false,
    onListInputChange,
    withPagination = true,
    extractList,
}) => {
    const [fetchPolicy, setFetchPolicy] = useState();
    const [listInput, setListInput] = useLocalStorage(
        ...getGraphQlListInputStorageArgs({ key, withPagination, defaultInput }),
    );

    const queryResult = useQuery(query, {
        variables: { input: listInput, ...variables },
        fetchPolicy,
    });
    const queryData = (queryResult.data || queryResult.previousData);
    const listData = queryData ? extractList(queryData) : null;

    const handleListInputChange = listInput => {
        setListInput(listInput);
        withNetworkOnlyStrategy && setFetchPolicy('network-only');
        onListInputChange && onListInputChange(listInput);
    };

    const setSearchFilters = filters => {
        const result = typeof filters === 'function' ? filters(listInput.searchFilters) : filters;
        handleListInputChange({
            ...listInput,
            searchFilters: result,
            pagination: { ...listInput.pagination, page: 1 },
        });
    };

    const setSort = orderBy => handleListInputChange({ ...listInput, orderBy });

    const setPage = page => handleListInputChange({
        ...listInput,
        pagination: { ...listInput.pagination, page },
    });

    const setItemsPerPage = itemsPerPage => handleListInputChange({
        ...listInput,
        pagination: {
            page: 1,
            itemsPerPage: itemsPerPage === 'all' ? null : +itemsPerPage,
        },
    });

    const paginationProps = withPagination && listData ? {
        current: listData.pageInfo.current,
        total: listData.pageInfo.pageCount,
        onSelect: setPage,
        itemsPerPage: listInput.pagination?.itemsPerPage ?? 'all',
        itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
        onItemsPerPageChange: setItemsPerPage,
    } : null;

    return {
        queryResult,
        listData,
        listInput,
        setSearchFilters,
        setPage,
        setItemsPerPage,
        setSort,
        paginationProps,
        listProps: {
            listContext: {
                listData,
                listInput,
                setSearchFilters,
                setSort,
                setPage,
                setItemsPerPage,
                paginationProps,
            },
            // List component props
            sortColumn: listInput.orderBy ? Object.keys(listInput.orderBy)[0] : null,
            sortDirection: listInput.orderBy ? Object.values(listInput.orderBy)[0] : null,
            loading: queryResult.loading,
            data: listData?.nodes || [],
            totalRows: listData?.pageInfo.total,
        },
    };
};

export default useGraphQlList;

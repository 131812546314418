// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createElement as ce, getComponent, api, escapeHtml, prepareUrl, BigButton, StatusMessage, Popup } from 'jsw';

/* SMB specific JavaScript classes */

export const ApsLicense = {
    buy(action) {
        window.open(action);
        return false;
    },

    initForm() {
        document.getElementById('apsLicense-uploadType-text').addEventListener('click', this.onChangeUploadType.bind(this));
        document.getElementById('apsLicense-uploadType-file').addEventListener('click', this.onChangeUploadType.bind(this));
    },

    onChangeUploadType() {
        if (document.getElementById('apsLicense-uploadType-text').checked) {
            document.getElementById('apsLicense-uploadText').disabled = false;
            document.getElementById('uploadFile').disabled = true;
        } else {
            document.getElementById('apsLicense-uploadText').disabled = true;
            document.getElementById('uploadFile').disabled = false;
        }
    },
};

/**
 * @deprecated
 */
export const ToolButton = BigButton;

export class ApplicationUpdate extends StatusMessage {
    _initConfiguration(config) {
        super._initConfiguration({
            ...config,
            type: 'warning',
        });
        this._data = this._getConfigParam('data', []);
        this._limit = this._getConfigParam('limit', 5);
    }

    _renderMessage() {
        const message = [super._renderMessage()];
        message.push(ce('br'));
        this._data.forEach(app => {
            message.push(
                ce('div.b-subitem',
                    `${app.name} `,
                    ce('span.hint', `(${this._renderHint(app)})`),
                    ' ',
                    ce('a.toggler.js-install', this.lmsg('install')),
                    '&nbsp; ',
                    ce('a.toggler.js-changelog', this.lmsg('changelog')),
                    ce('input', { type: 'hidden', value: JSON.stringify(app) }),
                ),
            );
        });
        if (this._data.length > this._limit) {
            message.push(ce('a.toggler.js-view-more', this.lmsg('viewMore', { number: this._data.length - this._limit })));
        }
        return message;
    }

    _renderHint(app) {
        if (app.instances.length === 1) {
            return app.instances[0].name;
        }
        return this.lmsg('instances', { total: app.instances.length });
    }

    _addEvents() {
        super._addEvents();

        this._componentElement.querySelectorAll('.b-subitem').forEach(item => {
            item.querySelector('.js-install').addEventListener('click', event => {
                this._onInstallEvent(event);
            });
            item.querySelector('.js-changelog').addEventListener('click', event => {
                this._onChangelogEvent(event);
            });
        });

        if (this._data.length > this._limit) {
            [...this._componentElement.querySelectorAll('.b-subitem')].slice(this._limit).forEach(item => {
                item.style.display = 'none';
            });
            this._componentElement.querySelector('.js-view-more').addEventListener('click', event => {
                this._onViewMoreEvent(event);
            });
        }
    }

    _onInstallEvent(e) {
        const app = JSON.parse(e.target.closest('.b-subitem').querySelector('input').value);
        Smb.updateApplication(app, this.getLocale(), e);
        e.target.closest('.b-subitem').querySelectorAll('a').forEach(a => {
            a.parentNode.removeChild(a);
        });
    }

    _onChangelogEvent(e) {
        const app = JSON.parse(e.target.closest('.b-subitem').querySelector('input').value);
        Smb.showApplicationChangelog(app, this.getLocale(), e);
    }

    _onViewMoreEvent() {
        [...this._componentElement.querySelectorAll('.b-subitem')].slice(this._limit).forEach(item => {
            item.style.display = item.style.display === 'none' ? '' : 'none';
        });
        this._componentElement.querySelector('.js-view-more').style.display = 'none';
    }
}

export const updateApplication = ({ name, instances }, locale, event) => {
    event.preventDefault();
    const beginOffset = Element.cumulativeOffset(event.target);
    const progressBar = getComponent('asyncProgressBarWrapper');
    const itemId = progressBar.fly(beginOffset,
        locale.lmsg('appInstall', { app: escapeHtml(name) }),
        () => {
            api.post(prepareUrl('/app-update/update-all'), {
                resourceIds: instances.map(({ id }) => id),
                name,
            })
                .then(() => {
                    progressBar.removePreparingItem(itemId);
                    progressBar.update();
                });
        },
    );
    if (event.target.closest('.caption-service-toolbar')) {
        event.target.closest('.caption-service-toolbar').querySelectorAll('.js-app-update').forEach(el => el.parentNode.removeChild(el));
    }
};

export const showApplicationChangelog = (app, locale, event) => {
    new Ajax.Request(prepareUrl(`/app-update/index/resourceId/${app.instances[0].id}/`), {
        method: 'get',
        onCreate() {
            new Popup({
                content: `<div class="ajax-loading">${locale.lmsg('loading')}</div>`,
                title: locale.lmsg('appChangelog', { app: escapeHtml(app.name) }),
            });
        },
        onSuccess(transport) {
            if ('' === transport.responseText) {
                return;
            }

            let contentText = '';
            try {
                const jsonResponse = JSON.parse(transport.responseText);
                if ('error' === jsonResponse.status) {
                    contentText = ('' != jsonResponse.statusMessages)
                        ? jsonResponse.statusMessages
                        : locale.lmsg('unableGetChangelog');
                }
            } catch {
                contentText = transport.responseText;
            }

            const popup = new Popup({
                content: contentText,
                title: locale.lmsg('appChangelog', { app: escapeHtml(app.name) }),
                onShow() {
                    document.querySelectorAll('.apps-box').forEach(function (e) {
                        const viewportHeight = document.viewport.getHeight();
                        const popupHeight = Element.getHeight(e.closest('.popup-container'));
                        const popupTop = Element.positionedOffset(e.closest('.popup-panel')).top;
                        if (popupHeight + (popupTop * 2) >= viewportHeight) {
                            const height = viewportHeight - (popupHeight - Element.getHeight(e)) - (popupTop * 2);
                            e.style.overflow = 'auto';
                            e.style.height = `${height}px`;
                        }
                    });
                },
                buttons: [
                    {
                        title: locale.lmsg('close'),
                        handler() {
                            popup.hide();
                        },
                    },
                ],
            });
        },
    });
    event.preventDefault();
};

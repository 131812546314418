// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import UAT from 'common/user-activity-tracking';
import { isNetworkError } from 'helpers/isNetworkError';
import addUrlParams from './addUrlParams';
import emptyFn from './emptyFn';

const RETRY_DELAY = 500;
const GET_REQUEST_RETRY_COUNT = 3;

const request = (url, config = {}, retryCount = 0) => {
    if (window.Ajax) {
        window.Ajax.activeRequestCount++;
    }

    const headers = {
        'X-Requested-With': 'XMLHttpRequest',
    };
    const tokenEl = document.getElementById('forgery_protection_token');
    if (tokenEl) {
        headers['X-Forgery-Protection-Token'] = tokenEl.content;
    }

    config = {
        credentials: 'same-origin',
        ...config,
        headers: {
            ...headers,
            ...config.headers,
        },
    };

    return fetch(url, config)
        .then(response => {
            if (response.status === 400) {
                window.location.reload();
                return new Promise(emptyFn);
            }
            if (!response.ok) {
                if (response.headers.get('Content-Type')?.startsWith('application/json')) {
                    return response.json()
                        .then(data => Promise.reject(new Error(data.message ?? JSON.stringify(data))));
                }
                return response.text()
                    .then(body => Promise.reject(new Error(body)));
            }
            return response.json();
        })
        .catch(error => {
            if (error instanceof TypeError && isNetworkError(error)) {
                if (retryCount > 0) {
                    return new Promise(resolve => setTimeout(() => resolve(), RETRY_DELAY))
                        .then(() => request(url, config, --retryCount));
                }

                throw error;
            }
            throw error;
        })
        .finally(() => {
            if (window.Ajax) {
                window.Ajax.activeRequestCount--;
            }
        });
};

export default {
    get: (url, params = {}) => request(addUrlParams(url, params), { method: 'GET' }, GET_REQUEST_RETRY_COUNT),
    post: (url, params = {}) => {
        UAT.dispatchAction('REQUEST', { url });
        const headers = {};
        let body;
        if (params instanceof FormData) {
            body = params;
        } else {
            body = JSON.stringify(params);
            headers['Content-Type'] = 'application/json; charset=utf-8';
        }
        return request(url, {
            method: 'POST',
            headers,
            body,
        });
    },
};

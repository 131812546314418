// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Component } from '../component';
import { SmallButton } from '../small-button';
import createComponent from '../createComponent';
import render from '../render';

class AdditionalActions extends Component {
    _initConfiguration(config) {
        super._initConfiguration({
            cls: 'actions-menu',
            ...config,
        });

        this._operations = this._getConfigParam('operations', null);
        this._titleAddCls = this._getConfigParam('titleAddCls', 'sb-more-actions');
    }

    _initComponentElement() {
        super._initComponentElement();

        render(this._componentElement, (
            '<div class="popup-box collapsed">' +
                '<table class="popup-wrapper" cellspacing="0"><tbody><tr>' +
                    '<td class="popup-container">' +
                        '<div class="c1"><div class="c2"><div class="c3"><div class="c4"><div class="c5">' +
                            '<div class="popup-heading">' +
                                '<div class="heading-area">' +
                                    '<span class="close"></span>' +
                                    '<h4>' +
                                        `<span>${this._getConfigParam('title', '')}</span>` +
                                    '</h4>' +
                                '</div>' +
                            '</div>' +
                            '<div class="popup-content">' +
                                '<div class="popup-content-area">' +
                                    `<ul id="${this._id}-operations">` +
                                    '</ul>' +
                                '</div>' +
                            '</div>' +
                        '</div></div></div></div></div>' +
                    '</td>' +
                '</tr></tbody></table>' +
            '</div>'
        ));
    }

    _addEvents() {
        super._addEvents();

        document.body.addEventListener('click', () => {
            this.collapse();
        });
    }

    enable() {
        this._titleButton.enable();
    }

    disable() {
        this._titleButton.disable();
    }

    toggle() {
        const popup = this._componentElement.querySelector('.popup-box');

        if (popup) {
            popup.classList.toggle('collapsed');
        }

        const button = this._componentElement.querySelector(`.${this._titleAddCls}`);

        if (button) {
            button.classList.toggle('active');
        }
    }

    collapse() {
        const popup = this._componentElement.querySelector('.popup-box');

        if (popup) {
            popup.classList.add('collapsed');
        }

        const button = this._componentElement.querySelector(`.${this._titleAddCls}`);

        if (button) {
            button.classList.remove('active');
        }
    }

    render() {
        super.render();

        this._titleButton = new SmallButton({
            id: `${this._id}-title-button`,
            title: this._getConfigParam('title', ''),
            cls: 's-btn',
            addCls: this._titleAddCls,
            handler: event => {
                event.preventDefault();
                event.stopPropagation();
                this.toggle();
            },
        });
        render(this._componentElement, this._titleButton);

        if (!this._operations) {
            return;
        }

        const operationsArea = this._componentElement.querySelector(`#${this._id}-operations`);
        this._operations.forEach(config => {
            const liElement = new Element('li');
            render(operationsArea, liElement);

            const operation = createComponent(config);
            const wrapperClass = operation.getWrapperClass();
            if (wrapperClass) {
                liElement.classList.add(wrapperClass);
            }

            render(liElement, operation);
        });

        if (this._getConfigParam('disabled', false)) {
            this.disable();
        } else {
            this.enable();
        }
    }
}

export default AdditionalActions;

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@plesk/ui-library';
import Link from 'components/common/Link';
import { prepareUrl } from 'jsw';

const PageContentHeaderNav = ({ items }) => {
    const ref = useRef(null);
    const active = useMemo(() => items.findIndex(({ containerCls }) => containerCls.split(' ').includes('active')) + 1, [items]);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const nav = ref.current;
        const tabs = nav.querySelector('.page-content-header-nav__tabs');
        const headerInner = document.querySelector('.pul-layout__header-inner');
        const stickyClassName = 'page-content-header-nav--sticky';

        const update = () => {
            const tabsRect = tabs.getBoundingClientRect();
            const headerInnerRect = headerInner.getBoundingClientRect();
            const top = (window.scrollY || window.pageYOffset) + nav.getBoundingClientRect().top;

            if (document.documentElement.scrollTop > top - headerInnerRect.height) {
                nav.classList.add(stickyClassName);
                nav.style.minHeight = `${tabsRect.height}px`;
                tabs.style.top = `${headerInnerRect.height}px`;
            } else {
                nav.classList.remove(stickyClassName);
                nav.style.minHeight = null;
                tabs.style.top = null;
            }
        };

        window.addEventListener('resize', update);
        window.addEventListener('scroll', update);
        document.addEventListener('mousewheel', update);

        update();

        return () => {
            window.removeEventListener('resize', update);
            window.removeEventListener('scroll', update);
            document.removeEventListener('mousewheel', update);
        };
    }, []);

    if (!items.length) {
        return null;
    }

    return (
        <div id="classic-mode-navigation" className="page-content-header-nav" ref={ref}>
            <Tabs className="page-content-header-nav__tabs" active={active}>
                {items.map(({ title, href }, index) => (
                    <Tab
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        data-cy={title}
                        title={title}
                        component={Link}
                        to={prepareUrl(href)}
                    />
                ))}
            </Tabs>
        </div>
    );
};

PageContentHeaderNav.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
        containerCls: PropTypes.string,
    })).isRequired,
};

export default PageContentHeaderNav;

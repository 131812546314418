// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import apolloClient from 'apolloClient';

import PROTECTED_DIRECTORY_ADD_PAGE from 'queries/ProtectedDirectoryAddPage.graphql';

export const protectedDirectoryAddRoute = {
    path: 'add',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/ProtectedDirectoryAddPage" */
        'components/pages/ProtectedDirectoryAddPage'
    ),
    loader: async ({ params }) => {
        const { domainId } = params;
        const { data: { domain: { hosting } } } = await apolloClient.query({
            query: PROTECTED_DIRECTORY_ADD_PAGE,
            variables: { domainId: btoa(`Domain:${domainId}`) },
        });

        return {
            domainId,
            isProtectedDirectoryLocationSupported: hosting?.isProtectedDirectoryLocationSupported || false,
        };
    },
};
